import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body d-flex flex-center flex-column p-9" }
const _hoisted_3 = {
  href: "#",
  class: "fs-4 text-gray-800 text-hover-primary fw-bolder mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrivacyName = _resolveComponent("PrivacyName")!
  const _component_TabNavigate = _resolveComponent("TabNavigate")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_VerticalEntity = _resolveComponent("VerticalEntity")!

  return (_openBlock(), _createBlock(_component_VerticalEntity, null, {
    entity: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.company.name), 1),
          _createVNode(_component_PrivacyName, {
            privacy: _ctx.company.privacy 
          }, null, 8, ["privacy"])
        ])
      ])
    ]),
    tab: _withCtx(() => [
      _createVNode(_component_TabNavigate, {
        items: _ctx.buildNavigate(_ctx.company)
      }, null, 8, ["items"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}