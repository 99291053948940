
import {computed, defineComponent, onUpdated, ref, watch} from "vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import {useGroupStore} from "@/store/group.store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {PinaLoadEntity} from "@/core/composite/composite";
import VerticalEntity from "@/components/common/VerticalEntity.vue";
import PrivacyName from "@/views/workspace/PrivacyName.vue";

export default defineComponent({
  name: "Workspace",
  components: {PrivacyName, VerticalEntity, TabNavigate},
  setup() {
    const groupStore = useGroupStore();
    const company = computed<any>(() => groupStore.entity);
    const submitting = ref(false);
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Workspace', [
        {link: true, router: '/company/' + company?.value?.group?.id + "/settings", text: company.value?.group?.name},
        {link: false, router: '', text: company.value.name}
      ])
    })

    watch(company, () => {
      setCurrentPageBreadcrumbsWithParams('Workspace', [
        {link: true, router: '/company/' + company?.value?.group?.id + "/settings", text: company.value?.group?.name},
        {link: false, router: '', text: company.value.name}
      ])
    })
    return {
      submitting,
      company,
      ...PinaLoadEntity(groupStore),
    }
  },
  methods: {
    buildNavigate(company) {
      let items = [
        {id: 'setting', router: `/workspace/` + company.id + `/settings`, name: 'Settings'},
      ]
      if (company.privacy === 'PRIVATE') {
        items.push({id: 'users', router: `/workspace/` + company.id + `/members`, name: 'Members'})
      }
      items.push(
        {id: 'dashboards', router: `/workspace/` + company.id + `/dashboards`, name: 'Dashboards'},
        {id: 'actionLogs', router: `/workspace/` + company.id + `/action-logs`, name: 'Actions'}
      )
      return items
    }
  }
})
